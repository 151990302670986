import React from 'react'

export const Logo = ({ color, className, strokeWidth }) => (
  /* 	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 60 60"
	>
		<path
			fill="none"
			stroke={color}
			strokeWidth={strokeWidth}
			d="M52.3 14.8a27 27 0 0 1-37.4 38.1L36 38.6m16.3-23.8L7.8 45.3A27 27 0 0 1 42.4 5.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit="10"
		/>
	</svg> */

  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 264 227"
  >
    <path
      fill={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M125.5 11.25C122.26 16.89 115.96 27.8 111.5 35.5C107.03 43.2 97.5 59.62 90.32 72C83.15 84.37 64.55 116.55 48.99 143.5C33.43 170.45 16.26 200.15 10.82 209.5C5.39 218.85 0.96 226.61 0.97 226.75C0.99 226.89 11.01 227 23.25 226.99L45.5 226.98C51.03 217.31 75.28 175.35 102.96 127.5C130.63 79.65 153.47 40.05 153.69 39.5C153.92 38.95 149.24 30.06 143.3 19.74C137.36 9.42 132.25 0.98 131.94 0.99C131.63 0.99 128.73 5.61 125.5 11.25ZM150.99 117.25C149.71 119.59 144.92 127.91 140.34 135.75C135.75 143.59 132 150.45 132 151C132 151.64 140 152 154 152C166.1 152 176 152.34 176 152.75C175.99 153.16 180.45 161.15 185.89 170.5L195.79 187.5L109.88 188.5C93.15 217.17 88.45 225.84 88.65 226.25C88.84 226.66 128.15 227 176 227C223.85 227 263.01 226.89 263.02 226.75C263.03 226.61 258.19 218.16 252.27 207.96C246.35 197.76 231.6 172.23 219.5 151.22L197.5 113.04L153.3 113"
    />
  </svg>
)
