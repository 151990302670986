module.exports = {
  defaultTitle: 'Abdul Ghani',
  logo: 'https://abdulghani.tech/favicon/logo-48.png',
  author: 'Abdul Ghani',
  url: 'https://abdulghani.tech',
  legalName: 'Abdul Ghani',
  defaultDescription:
    "I'm Abdul Ghani, a self-taught Full stack developer, Software Engineer, and a Blogger. I love building software that solves real-world problems, add value to society through technology.",
  socialLinks: {
    twitter: 'http://www.twitter.com/abdulghanitech',
    github: 'https://github.com/abdulghanitech',
    linkedin: 'https://www.linkedin.com/in/abdulghanitech',
    instagram: 'https://instagram.com/abdulghani.tech',
  },
  googleAnalyticsID: 'UA-111140066-1',
  themeColor: '#00c6ff',
  backgroundColor: '#00c6ff',
  siteRss: '/rss.xml',
  social: {
    twitter: '@abdulghanitech',
  },
  address: {
    city: 'Hyderabad',
    region: 'Telangana',
    country: 'India',
    zipCode: '500018',
  },
  contact: {
    email: 'hello@abdulghani.tech',
    phone: '+91-8801153636',
  },
  foundingDate: '2016',
}
