import React, { useContext } from 'react'
import Disqus from 'disqus-react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { SocialShare, PageTitle, Tag } from 'components/common'
import { ThemeContext } from 'providers/ThemeProvider'
import {
  ArticleWrapper,
  Back,
  Content,
  Comments,
  ArticleDate,
  Tags,
} from './styles'

export const Post = ({
  title,
  content,
  id,
  slug,
  date,
  categories,
  featured_media,
  fields: {
    readingTime: { text },
  },
  next,
  prev,
}) => {
  const { theme } = useContext(ThemeContext)
  const disqusShortName = 'abdulghani'
  const disqusConfig = {
    url: `https://abdulghani.tech/${slug}`,
    identifier: id,
    title: title,
  }
  return (
    <ArticleWrapper theme={theme}>
      <PageTitle>{title}</PageTitle>
      <Tags>
        {categories.map((item, i) => (
          <Tag tag={item.name} link={`/${item.slug}/`} key={i}>
            {item.name}
          </Tag>
        ))}
      </Tags>
      <ArticleDate>
        <i>{date} -</i>
        <i>{text}</i>
      </ArticleDate>
      <center>
        <Img
          fluid={
            featured_media
              ? featured_media.localFile.childImageSharp.fluid
              : null
          }
          alt={title}
        />
      </center>
      <br />

      <Content dangerouslySetInnerHTML={{ __html: content }} />
      <SocialShare title={title} path={'/' + slug} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {prev && prev.slug && (
          <Back>
            Previous article <br />
            <Link to={'/' + prev.slug}>{prev.title}</Link>
          </Back>
        )}

        {next && next.slug && (
          <Back>
            Next article <br />
            <Link to={'/' + next.slug}>{next.title}</Link>
          </Back>
        )}
      </div>

      <Comments>
        <Disqus.DiscussionEmbed
          shortname={disqusShortName}
          config={disqusConfig}
        />
      </Comments>
    </ArticleWrapper>
  )
}
