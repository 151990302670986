import React from 'react'

export const DribbbleIcon = ({
	width = '16',
	height = '18',
	color = '#fff',
	className = '',
}) => (
	<svg
		className={className}
		width={width}
		height={height}
		viewBox="0 0 14 14"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill={color}
			d="M8 11.719q-.328-1.883-1.094-3.891H6.89l-.016.008-.336.129q-.211.082-.789.383t-1.07.641-1.023.895-.805 1.156l-.117-.086Q4.172 12.126 6 12.126q1.031 0 2-.406zM6.555 6.977q-.164-.383-.414-.867-2.43.727-5.258.727-.008.055-.008.164 0 .969.344 1.848t.969 1.574q.391-.695.965-1.301t1.113-.973 1.02-.633.777-.375l.289-.102.102-.027q.071-.019.102-.035zM5.719 5.32q-.937-1.664-1.906-2.953-1.078.508-1.828 1.453t-1 2.125q2.359 0 4.734-.625zm5.344 2.493q-1.641-.469-3.195-.227.68 1.867 1 3.664.867-.586 1.445-1.48t.75-1.957zm-6.29-5.79q-.008 0-.016.008.008-.008.016-.008zm4.61 1.133Q7.938 1.875 6 1.875q-.594 0-1.211.148 1.023 1.328 1.922 2.984.539-.203 1.016-.473t.754-.48.512-.445.293-.316zm1.742 3.789q-.023-1.812-1.164-3.203l-.008.008q-.07.094-.148.191t-.34.348-.555.473-.781.508-1.027.504q.195.414.344.742.016.047.051.137t.059.129q.281-.039.582-.055t.574-.016.539.012.5.031.441.043.375.051.285.047.195.035zM12 7q0 1.633-.805 3.012t-2.184 2.184-3.012.805-3.012-.805-2.184-2.184T-.002 7t.805-3.012 2.184-2.184T5.999.999t3.012.805 2.184 2.184T12 7z"
		/>
	</svg>
)
